import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon-path.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const useChampionsSynergies = (searchParams) => {
  const filters = getDefaultedFiltersForChampions(searchParams, "synergies");
  const urlParams = getSearchParamsForChampions(true, filters);

  const { t } = useTranslation();
  const {
    lol: { championSynergies },
  } = useSnapshot(readState);

  const stats = championSynergies?.[urlParams.toString()];
  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) return null;

    const linkParams = new URLSearchParams(searchParams);

    return stats.map((champStats) => {
      const {
        patch,
        games,
        synergy_tier,
        win_rate,
        champion_id,
        champion_num_games,
        individual_position,
        ally_champion_id,
        ally_champion_num_games,
        ally_individual_position,
      } = champStats;

      const champion1Key = champions?.keys?.[champion_id];
      const champion2Key = champions?.keys?.[ally_champion_id];
      const champion1 = champions?.[champion1Key];
      const champion2 = champions?.[champion2Key];

      if (!champion1 || !champion2) return null;

      const champion1Name = champion1.name;
      const champion2Name = champion2.name;

      const champion1Params = new URLSearchParams(linkParams);
      champion1Params.delete("duoRole");
      champion1Params.set("role", champion1Params.get("role") || filters.role);

      const champion2Params = new URLSearchParams(linkParams);
      champion2Params.delete("duoRole");
      champion2Params.set(
        "role",
        champion2Params.get("duoRole") || filters.duoRole,
      );

      const champion1Link = `/${lolRefs.lolChampionPrefix}/${champion1Key}/build?${champion1Params}`;
      const champion2Link = `/${lolRefs.lolChampionPrefix}/${champion2Key}/build?${champion2Params}`;

      const Role1Icon = individual_position && getRoleIcon(individual_position);
      const Role2Icon =
        ally_individual_position && getRoleIcon(ally_individual_position);

      const TierIcon = getTierIcon(synergy_tier);

      return [
        {
          display:
            Role1Icon && Role2Icon ? (
              <>
                <img src={Role1Icon} width="20" height="20" />
                <img src={Role2Icon} width="20" height="20" />
              </>
            ) : null,
          value: null,
        },
        {
          display: (
            <>
              <a href={champion1Link}>
                <ChampionImg id={champion_id} size={28} />
              </a>
              <a href={champion2Link}>
                <ChampionImg id={ally_champion_id} size={28} />
              </a>
              <span className="champion-name">
                <a href={champion1Link}>{champion1Name}</a>{" "}
                <a href={champion2Link}>{champion2Name}</a>
              </span>
            </>
          ),
          value: `${champion1Name} ${champion2Name}`,
          className: "champion-meta",
        },
        {
          display: <img src={TierIcon} width="28" height="28" />,
          value: synergy_tier * -1,
        },
        {
          display: getLocaleString(win_rate, percentOptions),
          value: win_rate,
        },
        { display: getLocaleString(games), value: games },
        {
          display: getLocaleString(champion_num_games),
          value: champion_num_games,
        },
        {
          display: getLocaleString(ally_champion_num_games),
          value: ally_champion_num_games,
        },
        {
          display: patch,
          value: Number(patch) * 1000,
        },
      ];
    });
  }, [stats, champions, searchParams, filters]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:duoWinRate", "Duo Win Rate"),
        isStat: true,
      },
      { display: t("common:matches", "Matches"), align: "right", isStat: true },
      {
        display: t("lol:stat.c1Games", "C1 Games"),
      },
      {
        display: t("lol:stat.c2Games", "C2 Games"),
      },
      {
        display: t("lol:stat.patch", "Patch"),
        align: "right",
      },
    ],
    rows: rows,
  };
};

export default useChampionsSynergies;
